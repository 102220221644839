/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  font-family: 'associate_sans';
  src: url('assets/fonts/AssociateSansLight.otf') format('opentype');
}


/* loading */

.loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid #7898ff;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    position: relative;
    top: 50%;
    left: calc(50% - 30px);

  }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* */

  .line{
    width: 95%;
    margin-right: 5%;
    margin-top: 20px;
    border-top: 0;
    border-bottom: solid 1px #dbdada;
  }

  /*MODALS*/

  .regular-modal{
    .modal-wrapper{

   }}


  /*ITEMS */

.info-message-item{
  font-size: 0.7rem;
  margin-left: 0.3rem;
  padding-top: 0.2rem;

}

.error{
  color: rgb(200, 20, 20);
}
.warning{
  color:  rgb(200, 100, 20);
}
.info{
  color: rgb(20, 100, 200);
}


